@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@layer base {
  img {
    @apply inline-block;
  }
}

@supports(padding:max(0px)) {
  body, header, footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
  }
}

::-webkit-scrollbar-track{
  @apply bg-transparent rounded ;
}
::-webkit-scrollbar{
  @apply bg-gray-50 dark:bg-gray-700  w-2 rounded-full;
}
::-webkit-scrollbar-thumb{
  @apply bg-gray-700 rounded-full;
}

::-moz-selection {

}
::selection {

}
:-webkit-autofill {
  &, &:hover, &:focus {
    -webkit-box-shadow: 0 0 0 1000px #f9fafb inset;
    box-shadow: 0 0 0 1000px #f9fafb inset;
    transition: background-color 5000s ease-in-out 0s;
    border-radius: 0.75rem;
    font-family: Montserrat, sans-serif;
  }
}

:root {
  accent-color: #44403c;
}
